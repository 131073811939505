const blockList = "*<>/!%{}[]";
const safeStrings = new Set();
let lastValid: string | null = null;

const xssSafe = (str: string): boolean => {
  const xssHelper = (sub: string) => {
    if (sub.length === 0 || lastValid === sub || safeStrings.has(sub)) {
      return true;
    }

    const lastChar = sub[sub.length - 1];
    const charCode = lastChar.charCodeAt(0);

    if (
      !(charCode > 47 && charCode < 58) && // numeric (0-9)
      !(charCode > 64 && charCode < 91) && // upper alpha (A-Z)
      !(charCode > 96 && charCode < 123) // lower alpha (a-z)
    ) {
      for (let i = 0; i < blockList.length; i++) {
        if (i === 0) console.log("check");
        if (lastChar === blockList[i]) {
          return false;
        }
      }
    }

    return xssHelper(sub.slice(0, sub.length - 1));
  };

  const result = xssHelper(str);
  if (result) {
    safeStrings.add(str);
    lastValid = str;
  }
  return result;
};

export default xssSafe;
