/** 
 _ _ _                 _             
(_) | |_   _ _ __ ___ (_)_ __   __ _ 
| | | | | | | '_ ` _ \| | '_ \ / _` |
| | | | |_| | | | | | | | | | | (_| |
|_|_|_|\__,_|_| |_| |_|_|_| |_|\__,_|


Role based workflow


𝕋𝕙𝕒𝕟𝕜𝕤 𝕋𝕖𝕒𝕞 ℙ𝕣𝕠𝕒𝕔𝕥𝕚𝕧𝕖! 𝕋𝕙𝕚𝕤 𝕡𝕣𝕠𝕛𝕖𝕔𝕥 𝕨𝕒𝕤 𝕗𝕦𝕟!
- ℂ𝕙𝕖𝕥𝕒𝕟 (𝟙𝟙/𝟙𝟚/𝟚𝟘𝟚𝟙)

*/

import { Component } from "react";

import { isLocalhost } from "./Utils/mapEndPoints";
import HelixLoader from "./Utils/HelixLoader";

import "./Assets/Fonts/ibm-plex-sans-v8-latin/import.css";
import "./Assets/Fonts/ibm-plex-mono-v6-latin/import.css";
import { UserContext, IUserContext } from "./Components/Context";
import { setUserID } from "./Utils/Tracking/EventTracking";
import LoginIssues from "./Pages/LoginIssues";
import App from "./App";
import { setToken, generateToken } from "./Utils/getData";

const useAuth = true;

interface AuthProps {}
interface AuthState {
  current: "loaded" | "loading" | "error";
  username: string;
  email: string;
  userContext: IUserContext | null;
  token: string;
  id: number;
  type: "INTERNAL" | "EXTERNAL";
  authFail: boolean;
}

export interface AuthStatus {
  data: string;
  status: number;
}

class AuthCheck extends Component<AuthProps, AuthState> {
  constructor(props: any) {
    super(props);
    console.log("Built on: Tue Nov 26 2024 23:15:42 GMT+0000 (Coordinated Universal Time)");
    // All visualizations are shown by default
    this.state = {
      current: "loading",
      username: "User",
      email: "null@null.com",
      userContext: null,
      token: "testing",
      id: 4,
      type: "INTERNAL",
      authFail: false,
    };
  }

  componentDidMount() {
    if (useAuth) {
      if (!isLocalhost) {
        this.checkAuthLayer();
      } else {
        this.localAuth();
      }
      
    } else {
      this.setState({
        current: "loaded",
        username: "User",
        email: "null@null.com",
        userContext: null,
        token: "testing",
        id: 4,
        type: "INTERNAL",
        authFail: false,
      });
    }
  }

  handleErrors(response: any) {
    if (response && "ok" in response) {
      if (!response.ok) {
        throw Error("loggedOut");
      } else {
        return response;
      }
    } else {
      throw Error("unknowError");
    }
  }

  setUserContext(newData: IUserContext) {
    this.setState({
      ...this.state,
      userContext: newData,
    });
  }

  checkAuthLayer() {
    fetch("https://proactiveportal.illumina.com/authn-service/login", {
      credentials: "include",
      mode: "cors",
      referrerPolicy: "no-referrer-when-downgrade",
    })
      .then(this.handleErrors)
      .then((response) => response.json())
      .then((response: IUserContext) => {
        const email = response.email;
        const uname = email.split("@")[0];
        const type = response.type;
        const token = response.token;
        console.log(`Hello ${uname} welcome to proactive`);

        if (token) {
          setToken(response.token);

          this.setState({
            current: "loaded",
            username: uname || "",
            email: email,
            userContext: response,
            id: response.id,
            token: response.token,
            type,
          });
        }

        setUserID(email, response.role);

        //Not sure why this token is set
        window.localStorage.setItem("token", token);

        //Check if a redirect exists //

        const redirectTime = window.localStorage.getItem("redirect_time");
        const redirectURL = window.localStorage.getItem("redirect_url");

        redirectTime && window.localStorage.removeItem("redirect_time");
        redirectURL && window.localStorage.removeItem("redirect_url");

        if (redirectTime === null || redirectURL === null) {
          // ReactGA.initialize("UA-175206149-1" || "", {
          //   gaOptions: {
          //     userId: response.email || "unknown_user",
          //   },
          // });

          return;
        }

        const loginTime = new Date(parseInt(redirectTime as string));

        if (redirectURL && !isNaN(loginTime.getTime())) {
          const timeSinceRedirect = Date.now() - loginTime.getTime();

          // Allow user to login within 2 minutes
          if (timeSinceRedirect < 2 * 60 * 1000) {
            if (redirectURL !== window.location.href) {
              window.location.assign(redirectURL);
            }
          }
        }
      })
      .catch((error) => {
        console.log("okta error");
        switch (error.message) {
          case "loggedOut":
            const now = Date.now();
            console.log("okta error: logged out");
            //Save the redirect URL before authentication
            window.localStorage.setItem("redirect_url", window.location.href);
            window.localStorage.setItem("redirect_time", now.toString());

            let shouldRedirect = true;

            const timesFailed = window.localStorage.getItem("timesFailed");
            if (timesFailed === null) {
              window.localStorage.setItem("timesFailed", now.toString());
            } else {
              const allTimes = timesFailed
                .split(",")
                .map((time) => parseInt(time));
              const oneMinuteAgo = now - 60 * 1000;
              const attemptsWithinOneMinute = allTimes.filter(
                (time) => time > oneMinuteAgo
              );
              const newTimesFailed = [...attemptsWithinOneMinute, now];
              window.localStorage.setItem(
                "timesFailed",
                newTimesFailed.join(",")
              );
              if (newTimesFailed.length > 3) {
                shouldRedirect = false;
              }
            }

            if (!shouldRedirect) {
              this.setState({
                ...this.state,
                authFail: true,
                current: "loaded",
              });
            }

            //Redirect to OKTA auth
            shouldRedirect &&
              window.location.assign("https://proactiveportal.illumina.com/authn-service/login/callback");
            break;
          default:
            this.setState({
              current: "error",
            });
            break;
        }
      });
  }

  localAuth() {
    const tokenPromise = generateToken()
    .then((response: IUserContext) => {
      const email = response.email;
      const uname = email.split("@")[0];
      const type = response.type;
      const token = response.token;
      console.log(`Hello ${uname} welcome to proactive`);

      if (token) {

        this.setState({
          current: "loaded",
          username: uname || "",
          email: email,
          userContext: response,
          id: response.id,
          token: response.token,
          type,
        });
      }

      setUserID(email, response.role);
      window.localStorage.setItem("token", token);
    })
    .catch((err: any) => {
      throw Error("loggedOut");
    });
  }

  /**
   * ToggleZoom is passed to the Visualization object to trigger a zoom
   * since the zoom button is present inside the application.
   */

  //Use CSS to control display. Give UI a headstart
  render() {
    return (
      <>
        {this.state.current === "loaded" && (
          <UserContext.Provider
            value={{
              value: this.state.userContext,
              set: this.setUserContext.bind(this),
            }}
          >
            {this.state.authFail ? <LoginIssues /> : <App />}
          </UserContext.Provider>
        )}

        {this.state.current === "error" && (
          <p>Error! Are you connected to the VPN?</p>
        )}

        {this.state.current !== "error" && this.state.current !== "loaded" && (
          <HelixLoader />
        )}
      </>
    );
  }
}

export default AuthCheck;
