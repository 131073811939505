import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTracker = () => {
  const loc = useLocation();

  //Send type of page

  useEffect(() => {
    const page_title = (() => {
      const pageNames: string[] = [
        "account",
        "run",
        "instrument",
        "ProactivePortal",
      ];
      const page_title: string | null = pageNames.reduce(
        (acc: string | null, name: string) => {
          if (!acc && loc.pathname.indexOf(name) > -1) {
            return name;
          } else {
            return acc;
          }
        },
        null
      );

      return page_title || document.title;
    })();
    if ("gtag" in window) {
      window.gtag("event", "page_view", {
        page_title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      });
    }
  }, [loc]);

  return <p style={{ display: "none" }}>.</p>;
};

export default PageTracker;
