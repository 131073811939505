
export const getQueryVar = (varName) => {
  const url = new URL(window.location.href);
  let param = url.searchParams.get(varName);
  return param || "";
};

// export const getRunId = (opt) => {
//   return getQueryVar(opt, "runid");
// };

// export const getIpsRunId = (opt) => {
//   return getQueryVar(opt, "ipsRunId");
// };

// export const getEnv = (opt) => {
//   return getQueryVar(opt, "env");
// };

// export const getInstrumentId = (opt) => {
//   return getQueryVar(opt, "instrumentId");
// };

// export const getAccountId = (opt) => {
//   return getQueryVar(opt, "accountId");
// };
