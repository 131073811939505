import {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import { Input, Dropdown, Menu, Typography } from "antd";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { getSearchResult } from "../../Utils/getData";
import xssSafe from "../../Utils/xssSafe";

import { UserContext } from "../Context";

import eventTracking, {
  categoryNames,
  actionNames,
} from "../../Utils/Tracking/EventTracking";

const { Item } = Menu;
const { Link } = Typography;

const SearchInput = styled(Input)`
  padding: 10px 17px;
  border-radius: 5px;
  /* border: none; */
`;

const SearchMenu = styled(Menu)`
  max-height: 500px;
  overflow: auto;
  position: relative;
  &.ant-dropdown-menu {
    padding: 0;
    margin: 4px 0;
  }
`;

const TitleContainer = styled.div`
  position: sticky;
  z-index: 10;
  padding: 5px 12px;
  background-color: #f5f5f5;
  color: #8b8b8b;
  &.hasItems {
    color: #2b8700;
    cursor: pointer;
  }
`;

const NotificationButton = styled.div`
  padding: 10px;
  text-align: right;
  cursor: pointer;
  color: white;
  margin-right: -10px;
  .ant-badge {
    height: 100%;
    padding: 13px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    font-size: 17px;
  }
`;

const GreenLink = styled.a`
  &.hasLink {
    color: #2b8700;
    text-decoration: underline;
  }
`;

export interface ISearchResult {
  searches: ISearchResultShape[];
}

export interface ISearchResultShape {
  text: string;
  attribute: string | null;
  type: string;
}

export interface ISearchAccountShape extends ISearchResultShape {
  erpCustomerId?: string;
}

type SearchResults = {
  INSTRUMENT: ISearchResultShape[];
  ACCOUNT: ISearchAccountShape[];
};

type AllSearchResults = {
  [Key: string]: SearchResults | "loading" | "error";
};

const SearchBar: React.FC = () => {
  const CHARACTER_MIN = 3;
  const [showResult, setShowResult] = useState(false);
  const [queryTerm, setQueryTerm] = useState("");
  const menu = useRef<HTMLDivElement | null>(null);
  const [searchResultParent, setSearchParent] =
    useState<HTMLUListElement | null>(null);

  const emptySearch = { "": { INSTRUMENT: [], ACCOUNT: [] } };
  const [searchResult, setSearchResult] =
    useState<AllSearchResults>(emptySearch);
  const [titleHeight, setTitleHeight] = useState(0);
  const [overLay, setOverLay] = useState<JSX.Element | null>(null);
  const history = useHistory();
  const searchStartTime: { [key: string]: number } = {};

  const type = useContext(UserContext).value?.type;
  const role = useContext(UserContext).value?.role;
  const isInternal = type === "INTERNAL";
  const userRole = role ? role : "";

  useEffect(() => {
    if (queryTerm.length >= CHARACTER_MIN) {
      if (!(queryTerm in searchResult)) {
        setSearchResult({
          ...searchResult,
          [queryTerm]: "loading",
        });

        searchStartTime[queryTerm] = Date.now();
        getSearchResult(queryTerm, isInternal, userRole)
          .then((search: ISearchResult) => {
            if (search.searches) {
              setSearchResult({
                ...searchResult,
                [queryTerm]: {
                  INSTRUMENT: search.searches.filter(
                    (s) => s.type === "INSTRUMENT"
                  ),
                  ACCOUNT: search.searches.filter((s) => s.type === "ACCOUNT"),
                },
              });
              const loadEnd = Date.now();
              const time = (loadEnd - searchStartTime[queryTerm]) / 1000;
              eventTracking({
                category: categoryNames.search,
                action: actionNames.load_complete,
                label: queryTerm,
                value: time,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            setSearchResult({
              ...searchResult,
              [queryTerm]: "error",
            });
          });
      }
    }
  }, [queryTerm]);

  useLayoutEffect(() => {
    if (menu.current) {
      const firstTitle = menu.current.querySelector(".title");
      if (firstTitle) {
        const height = firstTitle.getBoundingClientRect().height;
        if (height) {
          setTitleHeight(height);
        }

        const searchParent = firstTitle.closest("ul") as HTMLUListElement;
        setSearchParent(searchParent);
      }
    }
  }, [menu, menu.current]);

  useEffect(() => {
    let overlayText = "";
    const category: ("INSTRUMENT" | "ACCOUNT")[] = [
      "INSTRUMENT",
      "ACCOUNT"
    ];

    if (queryTerm.length < CHARACTER_MIN) {
      overlayText = "Enter 3 characters or more to search";
    } else {
      if (searchResult[queryTerm] === "error") {
        overlayText = "Something went wrong. Refresh to try again.";
      } else if (searchResult[queryTerm] === "loading") {
        overlayText = "Loading...";
      } else if (typeof searchResult[queryTerm] === "object") {
        const result: SearchResults = searchResult[queryTerm] as SearchResults;
        setOverLay(
          <SearchMenu>
            {category.map((cat, index) => {
              return (
                <>
                  <TitleContainer
                    ref={index === cat.length - 1 ? menu : null}
                    style={
                      titleHeight > 0
                        ? {
                            top: `${titleHeight * index}px`,
                            bottom: `${
                              titleHeight * (category.length - index - 1)
                            }px`,
                          }
                        : {}
                    }
                    onMouseDown={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();

                      if (searchResultParent) {
                        const target = ev.target as HTMLElement;
                        if (!!target) {
                          const offsetTop = target.parentElement?.offsetTop;
                          searchResultParent.scrollTop = offsetTop || 0;
                        }
                      }
                    }}
                    className={`${result[cat].length > 0 ? "hasItems" : ""}`}
                  >
                    <Item className={"title"}>
                      {`${cat} (${result[cat].length})`}
                    </Item>
                  </TitleContainer>
                  {result[cat].map((r) => {
                    let linkText = `${r.text} ${
                      !!r.attribute && cat !== "ACCOUNT"
                        ? "(" + r.attribute + ")"
                        : ""
                    }`;
                    if (cat === "ACCOUNT" && "erpCustomerId" in r)
                      linkText += ` (ERP ID: ${r.erpCustomerId})`;
                    let link = "";
                    if (cat === "INSTRUMENT" && r.text) {
                      link = `/instrument/${r.text}`;
                    } else if (cat === "ACCOUNT" && r.attribute) {
                      link = `/account/${r.attribute}`;
                    }
                    return (
                      <Item
                        onMouseDown={(ev) => {
                          eventTracking({
                            category: categoryNames.search,
                            action: actionNames.click,
                            label: linkText,
                          });

                          window.location.href = link;
                        }}
                      >
                        <GreenLink
                          className={link !== "" ? "hasLink" : ""}
                          // href={link}
                        >
                          {linkText}
                        </GreenLink>
                      </Item>
                    );
                  })}
                </>
              );
            })}
          </SearchMenu>
        );
      }
    }

    if (overlayText !== "") {
      setOverLay(
        <Menu data-name="search-overlay">
          <Item>{overlayText}</Item>
        </Menu>
      );
    }
  }, [searchResult, queryTerm]);

  return overLay ? (
    <Dropdown overlay={overLay} visible={showResult} trigger={["click"]}>
      <SearchInput
        placeholder="search"
        data-name="header-search"
        value={queryTerm}
        onFocus={(e) => setShowResult(true)}
        onBlur={(e) => setShowResult(false)}
        onChange={(e) => {
          xssSafe(e.target.value) && setQueryTerm(e.target.value);
        }}
      />
    </Dropdown>
  ) : (
    <></>
  );
};

export default SearchBar;
