import styled from "styled-components";

export default styled.div`
  /* max-width: 1440px; */
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  display: block;
  position: relative;
`;
