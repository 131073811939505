import styled from "styled-components";

const Container = styled.div`
  margin: 30px;
`;

const LoginIssues = () => {
  return (
    <Container>
      <h2>Illumina Proactive Portal Login Unsuccessful</h2>
      <p>There are a few possible reasons you are seeing this screen.</p>
      <ul>
        <li>
          You do not have access to the Illumina Proactive Portal due to your
          role. If you believe this is in error, please submit a ServiceNow
          ticket for Illumina Proactive Portal access.
        </li>
        <li>
          You are not on Illumina VPN. Illumina Proactive Portal requires
          Illumina VPN access.
        </li>
        <li>
          Chrome samesite settings are blocking successful log in. Illumina
          Proactive Portal will retry the log in 3 times before reaching this
          screen.
        </li>
      </ul>
      <p>For reason #3, please try the following steps to resolve the issue</p>
      <ul>
        <li>
          In Google Chrome, enter chrome://flags/ in the browser bar and hit
          enter.
        </li>
        <li>Search for “SameSite by default cookies”.</li>
        <li>
          Set the setting to “Disabled”. (Do not adjust any other settings).
        </li>
        <li>Restart Chrome browser for settings to take effect.</li>
        <li>Log into DCP</li>
      </ul>
    </Container>
  );
};

export default LoginIssues;
