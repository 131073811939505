import { materialColors, materialChartColors } from "./materialColors";

export const colorCodes = {
  proactiveGreen: "#29974E",
  proactiveBlue: "#1F83DE",
  "pure-white": "#FFF",

  "light-grey": "#EFEFEF",
  "light-grey-2": "#F4F4F4",
  "light-grey-3": "#e8e8e8",

  textGrey: "#8B8B8B",
  textGreen: "#2b8700",
  "light-green-0": "rgb(212,233,195)",
  "light-green-1": "#D4E9C3",
  "light-green-2": "#29974E",

  // https://coolors.co/ef476f-ffd166-06d6a0-118ab2-073b4c
  chartColors: [
    // "#06d6a0",
    // "#118ab2",
    // "#1131b2",
    // "#6d0cc2",
    // "#94069c",
    // "#a10a80",
    "#0B84A5",
    "#6F4E7C",
    "#9DD866",
    "#CA472F",
    "#8DDDD0",
  ],

  chart2Colors: ["#003F5C", "#7A5195", "#EF5675", "#FFA600"],

  interopTileChartColors: [
    "#338EE2",
    "#FFB30E",
    "#DC267F",
    "#64209C",
    "#FE6100",
    "#5EA918",
    "#8DDDD0",
    "#2D2F8F"
  ],

  chartRunning_fill: "#ef476f",
  chartRunning_stroke: "#8a6716",
  chart_service_event: "#ffba19",

  info: "#52A6F5",
  warning: "#FFB30E",
  critical: "#E67240",

  InfoText: "#338EE2",
  WarningText: "#FFA60E",
  CriticalText: "#DE5D26",
  criticalLight: "#e9c9c3",

  warningBG: "#edbb3d",

  passText: "#006100",
  failText: "#9C0006",
  dnqText: "#656565",
  failLight: "#FFD4B3",
  dnqLight: "#F4F4F4",

  materialColors,
  materialChartColors,

  highlightCell: "rgb(243 189 51 / 20%)",
};

export const colors = {
  "page-bg": colorCodes["pure-white"],
};

export const styles = {
  margin1: "1.25rem",
  borderRadius1: "0.875rem",
  chartSVGHeight: 450,
  smallBorderRadius: "5px",
  largeBorderRadius: "30px",

  //4rem = 64px
  gutterWidth1: 64,
  chartFont: "IBM Plex Mono",

  //charts
  bgAxisLine: colorCodes["light-grey"],
  dropDownBg: colorCodes["light-grey-2"],
  dropDownWidth: "9.375rem",
  titleSize: "1.5rem",
  smallTitleSize: "1rem",
  smallText: "14px",
  smallTextColor: colorCodes.textGrey,
  barColor: colorCodes["light-green-1"],
  barBorder: colorCodes["light-green-2"],

  loadingCircleColor: colorCodes["light-grey-3"],

  utilizationCardSize: 250,

  optionWidth: "9.375rem",
  optionTagBg: colorCodes["light-grey-3"],

  onlineDot: colorCodes["proactiveGreen"],
  offlineDot: colorCodes["textGrey"],

  badgeColor: colorCodes.proactiveGreen,

  headerTitleCopy: "2rem",
  statusButtonFont: "0.625rem",
  statusButtonInactiveColor: colorCodes["light-green-1"],

  buttonBg: colorCodes["light-grey-2"],
  activeButtonBg: colorCodes["light-green-1"],

  monoFont: "IBM Plex Mono",
  bannerBg: colorCodes["light-grey-2"],

  statusTitleColor: colorCodes["textGrey"],
  statusTextSize: "1.5rem",

  zeroAlertTextColor: colorCodes["light-grey-3"],
  statusTooltipIconColor: colorCodes["light-green-2"],
  bannerAnimationSpeed: "300ms",

  footerBg: colorCodes["light-grey"],
  hardwareChartColors: colorCodes["chart2Colors"],

  gapChart: "2.75rem",
  errorTextColor: colorCodes["critical"],

  labelColor: colorCodes["textGrey"],
  tagBG: colorCodes["light-grey-2"],

  tableHistoryBG: colorCodes["light-grey-2"],

  selectBG: colorCodes["light-grey-2"],
  selectTabsBG: colorCodes["light-grey-3"],

  gutterWidth2: 16,

  placeHolderBorder: colorCodes["light-green-1"],
  runTimePeriodLabel: colorCodes["textGrey"],

  expiredBG: colorCodes["highlightCell"],
};
