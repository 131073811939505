import styled from "styled-components";

const HelixLoader = (props) => (
  <div className="helix-loader">
    <div className="helix-dots">
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </div>
    <div className="helix-backdrop"></div>
  </div>
);

export const BlockHelixLoader = styled(HelixLoader)`
  position: relative;
  height: inherit;
  min-height: 180px;
`;

export default HelixLoader;
