import styled from "styled-components";
import { Tag } from "antd";
import { styles, colorCodes } from "../index";

const TagStyle = styled(Tag)`
  &.ant-tag {
    border-radius: ${styles.largeBorderRadius};
    color: ${colorCodes["pure-white"]};
    border-color: ${colorCodes.proactiveBlue};
    background: ${colorCodes.proactiveBlue};
    &.clickable {
      cursor: pointer;
    }
    &.inactive {
      color: ${styles.labelColor};
    }
  }
`;

export default TagStyle;
