const stringPathMap = [
  ["flowcellchart/options", "/mock-api/exp1/options/flowcellchart.json"],
  ["cycleplot/options", "/mock-api/exp1/options/cycleplot.json"],
  ["laneplot/options", "/mock-api/exp1/options/laneplot.json"],
  ["qscoreheatmap/options", "/mock-api/exp1/options/qscoreheatmap.json"],
  ["qscorehistogram/options", "/mock-api/exp1/options/qscorehistogram.json"],
  ["flowcellchart/data", "/mock-api/exp1/flowcellchart1.json"],
  ["cycleplot/data", "/mock-api/exp1/cycleplot1.json"],
  ["laneplot/data", "/mock-api/exp1/laneplot1.json"],
  ["qscoreheatmap/data", "/mock-api/exp1/qscoreheatmap2.json"],
  ["qscorehistogram/data", "/mock-api/exp1/qscorehistogram2.json"],
];

export default (url: any, fileName?: string) => {
  const host = window.location.hostname;

  if (host !== "localhost") {
    return url;
  }

  if (fileName) {
    return `/mock-api/exp2/${fileName}.json`;
  }

  for (let i = 0; i < stringPathMap.length; i++) {
    const str = [...stringPathMap[i]];
    const path = str.pop();

    const allMatch = str.reduce((acc: boolean, val: string) => {
      if (url.indexOf(val) > -1) {
        return acc && true;
      } else {
        return false;
      }
    }, true);

    if (allMatch) {
      return path;
    }
  }

  return url;
};

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
