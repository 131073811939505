import axios from "axios";
import { IRunSummary } from "../Components/Banner/StatusTypes/RunSummaryStatus";
import {
  IAccountUtilization,
  IAccountUtilizationResponse,
  IInstrumentUtilization,
  HardwareMetricsList,
  HarwareMetricChartData,
  IServiceEvent,
  IBins,
  IItems,
  IDetailData,
  IInsightData,
  IInsight
} from "../Components/Chart2.1/interface";
import {
  IUserCase,
  IMyInstruments,
  IInsAlert,
  IMyAccountData,
  IUserInsights,
  IUserCaseCall,
  IUserRecentRun,
  IUserRecentAlert,
  IMyAccountBanner,
  IMyAccountBannerInfo,
  IMyAccountBannerConnectivity,
  IMyAccountBannerAlerts,
  IMyAccountBannerRuns,
  IMyAccountBannerUtilization,
} from "../Components/Home/interface";

import { IRunSize } from "../Components/DownloadModal";

import {
  IInstrumentBanner,
  IInstrumentBannerConfig,
  IInstrumentBannerStatus,
  IInstrumentBannerRuns,
  IInstrumentBannerUtilization,
  IActiveAlert,
  InstrumentBreadcrumb,
} from "../Components/Instrument/InstrumentBanner";

import {
  IAccountBannerInfo,
  IAccountBannerConnectivity,
  IAccountBannerAlerts,
  IAccountBannerRuns,
  IAccountBannerUtilization,
} from "../Components/Account/AccountBanner";
import {
  IRunFileSizeDetails, 
  IRunDownloadDetails 
} from "../Components/DownloadModal";

import { RunBreadcrumb } from "../Pages/Run/index";
import { InstrumentStatus, IInstrumentConnectivity } from "../Pages/Instrument";
import { ErrorWarningData } from "../Components/Instrument/ErrorsAndWarnings";
import { IChartData, IPatternedFlowcell } from "../Components/Run/RunCharts";
import { UserSpoofingData } from "../Components/UserSpoofing";
import { IAlert } from "../Components/Instrument/AlertTable";
import { IActiveAlertData } from "../Components/Banner/StatusTypes/AlertStatus";

import { IRunParamAndConsumable } from "../Components/Run/RunParamsAndConsumables";

import {
  IQueryParamOptions,
  IQueryParamPreviewTable,
  ISaveQueryParam,
} from "../Components/CustomQuery";

import {
  IRunStatus,
  IQueryParam,
  IQuerySummaryParam,
  IFileSummary,
  IFileDownloadParam,
  IFileDownload,
  IQueryParamTable
} from "../Components/LogParsing";

import {
  ISearchResult,
  ISearchResultShape,
  ISearchAccountShape,
} from "../Components/SearchBar";

import { IAlertData, ISendAlertData } from "../Components/AlertCMS";
import { IUserAccountList } from "../Components/Account/AccountFollow";
import {
  IUserInstrumentList,
  IFollowedInstrument,
} from "../Components/Instrument/InstrumentFollow";

import { IUserContext } from "../Components/Context";

axios.defaults.headers.common["Api-Key"] = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_API_KEY : "UFJPQUNUSVZFQkFDS0VOREpBV04=";
axios.interceptors.response.use(undefined, function (error) {
  if (error.response) {
    if (error.response.status === 401) {
      window.location.assign("/unauthorized");
    }
  }
});

const authUrl = "/authn-service";
const baseUrl = "/proactive-service";
const version = "v1pre3";

const REACT_APP_LOCAL_USERNAME = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_LOCAL_USERNAME : '';
const REACT_APP_LOCAL_PASSWORD = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_LOCAL_PASSWORD : '';
const REACT_APP_PROXY_URL = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_PROXY_URL : '';

const getKey = (url: string, params: any = {}) => {
  const paramArray = Object.keys(params)
    .sort()
    .map((el) => {
      if (typeof params[el] === "string") {
        return `${el}=${params[el] as string}`;
      } else if (typeof params[el] === "object") {
        return `${el}=${JSON.stringify(params[el])}`;
      }
    })
    .sort()
    .join("&");
  return [url, paramArray].join("?");
};

const dataStore: any = {};

export const generateToken = () => {
  const url: string = [REACT_APP_PROXY_URL, "authn-service", "generate-token"].join("/");
  const params: any = {};

  params["accountName"] = REACT_APP_LOCAL_USERNAME;
  params["password"] = REACT_APP_LOCAL_PASSWORD;

  return new Promise<IUserContext>((resolve, reject) => {
    axios
      .get(url, { params })
      .then((response) => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};

export const setToken = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export const axiosExport = (...args) => {
  return axios(args[0], args[1] || undefined);
};


let lastCSRFset: number | null = null;

export const getUserTable = () => {
  const url: string = [authUrl, "user"].join("/") || "";
  
  const requestKey = getKey(url);

  return new Promise<UserSpoofingData[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url)
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const impersonateLogin = (userId: number) => {
  const url: string = [authUrl, "user", "impersonate", userId, "login"].join("/") || "";

  return new Promise<IUserContext>((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
        resolve(response.data);
      })
      .catch(reject);
  });
};

export const impersonateLogout = () => {
  const url: string = [authUrl, "user", "impersonate", "logout"].join("/") || "";

  return new Promise<IUserContext>((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
        resolve(response.data);
      })
      .catch(reject);
  });
};

export const getSequencingStats = (runId: string, env: string) => {
  const url: string =
    [baseUrl, version, "env", env, "runs", runId, "sequencingstats"].join(
      "/"
    ) || "";
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.Response);
      })
      .catch((err) => reject(err));
  });
};

export const getRunSummary = (runId: string, ipsRunId: string, env: string) => {
  const url: string = [baseUrl, "run", "summary"].join("/");
  const params: any = {};

  if (ipsRunId) {
    params["ipsRunId"] = ipsRunId;
  } else if (runId) {
    params["bsRunId"] = runId;
    params["environment"] = env;
  }

  return new Promise<IRunSummary>((resolve, reject) => {
    axios.get(url, { params }).then((response) => {
      resolve(response.data as IRunSummary);
    });
  });
};

export const getRunDetail = (runId: string, env: string) => {
  const url: string = [baseUrl, "run", runId, "environment", env, "detail"].join("/");
  const params: any = {};

  return new Promise<IDetailData>((resolve, reject) => {
    axios.get(url, { params }).then((response) => {
      resolve(response.data as IDetailData);
    });
  });
};

export const getRunInsight = (runId: string, env: string) => {
  const url: string = [baseUrl, "run", runId, "environment", env, "insight"].join("/");
  const params: any = {};

  return new Promise<IInsightData>((resolve, reject) => {
    axios.get(url, { params }).then((response) => {
      resolve(response.data as IInsightData);
    });
  });
};

export const getInstrumentDetail = (instrumentId: string) => {
  const url: string = [baseUrl, "instrument", instrumentId, "detail"].join("/");
  const params: any = {};

  return new Promise<IDetailData>((resolve, reject) => {
    axios.get(url, { params }).then((response) => {
      resolve(response.data as IDetailData);
    });
  });
};

export const getInstrumentInsight = (instrumentId: string) => {
  const url: string = [baseUrl, "instrument", instrumentId, "insight"].join("/");
  const params: any = {};

  return new Promise<IInsightData>((resolve, reject) => {
    axios.get(url, { params }).then((response) => {
      resolve(response.data as IInsightData);
    });
  });
};

export const getInstrumentStats = (params: { serialNumbers: string }) => {
  const url: string = [baseUrl, "instrument", "banner"].join("/");
  return new Promise<IInstrumentBanner>((resolve, reject) => {
    axios
      .get(url, { params })
      .then((res) => {
        resolve(res.data[0]);
      })
      .catch((err) => reject(err));
  });
};

export const getInstrumentBannerStatus = (params: { serialNumber: string }) => {
  const url: string = [baseUrl, "instrument", "banner", "status"].join("/");
  const requestKey = getKey(url, params);
  return new Promise<IInstrumentBannerStatus>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getInstrumentConfig = (params: { serialNumber: string }) => {
  const url: string = [baseUrl, "instrument", "config"].join("/");
  const requestKey = getKey(url, params);
  return new Promise<IInstrumentBannerConfig>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getInstrumentConnectivity = (params: { serialNumber: string }) => {
  const url: string = [baseUrl, "instrument", "banner", "connectivity"].join("/");
  const requestKey = getKey(url, params);
  return new Promise<IInstrumentConnectivity>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getInstrumentRuns = (params: { serialNumber: string }) => {
  const url: string = [baseUrl, "instrument", "banner", "runs"].join("/");
  const requestKey = getKey(url, params);
  return new Promise<IInstrumentBannerRuns>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getInstrumentBannerUtilization = (
  params: { serialNumber: string }
) => {
  const url: string = [baseUrl, "instrument", "banner", "utilization"].join("/");
  const requestKey = getKey(url, params);
  return new Promise<IInstrumentBannerUtilization>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getActiveInstrumentAlertsData = (instrumentId: string) => {
  const url: string = [baseUrl, "instrument", instrumentId, "alert", "insight"].join("/");

  const requestKey = getKey(url);

  return new Promise<IActiveAlertData[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getActiveRunAlertsData = (runId: string, env: string) => {
  const url: string = [baseUrl, "run", runId, "environment", env, "alert", "insight"].join("/");

  const requestKey = getKey(url);

  return new Promise<IActiveAlertData[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getRunAlertsData = (runId: string, env: string) => {
  const url: string = [baseUrl, "run", runId, "environment", env, "alert"].join("/");

  const requestKey = getKey(url);

  return new Promise<{ items: IAlert[] }>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getAlertsData = (instrumentId: string, time: string, status?: string ) => {
  const url: string = [baseUrl, "alert"].join("/");
  let params = {
    serialNumbers: instrumentId,
    timeDuration: time,
    alertStatus: status
  };

  const requestKey = getKey(url, params);

  return new Promise<{ items: IAlert[] }>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getAlertsData_Duplicate = (instrumentId: string, time: string, status?: string ) => {
  const url: string = [baseUrl, "alert"].join("/");
  let params = {
    serialNumbers: instrumentId,
    timeDuration: time,
    alertStatus: status
  };

  const requestKey = getKey(url, params);

  return new Promise<IInsAlert[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data.items;
          resolve(res.data.items);
        })
        .catch((err) => reject(err));
    }
  });
};

export interface IGetInstrumentUtilization {
  binType?: "week" | "month";
  timeDuration?: "3month" | "6month" | "12month";
  serialNumbers?: string;
  fromDate?: string;
  toDate?: string;
}

export const getInstrumentUtilization = (
  parameters: IInstrumentUtilization
) => {
  const url = [baseUrl, "instrument", "utilization"].join("/");
  const params = { ...parameters };

  const requestKey = getKey(url, params);

  return new Promise<IItems>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getAccountUtilization = (parameters: IAccountUtilization) => {
  const url = [baseUrl, "account", "utilization"].join("/");
  const params = { ...parameters };

  const requestKey = getKey(url, params);

  return new Promise<IAccountUtilizationResponse>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export interface IGetHistoricalRuns {
  fromDate?: string;
  toDate?: string;
  serialNumbers?: string;
  timeDuration?: string;
  withRunParameters?: "true" | "false";
  isConsumablesOnly?: "true" | "false";
}

export const getHistoricalRuns = (params: IGetHistoricalRuns) => {
  const url = [baseUrl, "run"].join("/");

  const requestKey = getKey(url, params);

  return new Promise<IBins[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getHistoricalRunParameters = (params: IGetHistoricalRuns) => {
  const url = [baseUrl, "run", "run-parameters"].join("/");
  const validParams = { ...params };

  const requestKey = getKey(url, params);

  return new Promise<any[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params: validParams })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getLoadingConcentration = (params: IGetHistoricalAndService) => {
  const url = [baseUrl, "run", "metrics"].join("/");
  const requestKey = getKey(url, params);

  const validParams = { ...params };

  return new Promise<
    {
      runId: string;
      loadingConcentration: number;
      occupancyPercent: number;
    }[]
  >((resolve, reject) => {
    //force reload
    if (false && requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, {
          params: validParams,
        })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getIPSRun = (id: string | number) => {
  const url = [baseUrl, "run", id].join("/");

  const requestKey = getKey(url);

  return new Promise<IBins>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export interface IGetServiceEvents {
  serialNumbers?: string;
  timeDuration?: string;
}

export const getServiceEvents = (
  params: IGetServiceEvents = { timeDuration: "year" }
) => {
  const url = [baseUrl, "instrument", "service-event"].join("/");

  const requestKey = getKey(url, params);
  return new Promise<IServiceEvent[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getAccountBannerInfo = (
  accountId: string | number,
  isActiveInstrument = "true"
) => {
  const url = [baseUrl, "account", accountId, "banner", "info"].join("/");
  const params = { isActiveInstrument };
  const requestKey = getKey(url, params);
  return new Promise<IAccountBannerInfo>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getAccountBannerConnectivity = (
  accountId: string | number,
  isActiveInstrument = "true"
) => {
  const url = [baseUrl, "account", accountId, "banner", "connectivity"].join("/");
  const params = { isActiveInstrument };
  const requestKey = getKey(url, params);
  return new Promise<IAccountBannerConnectivity>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getAccountBannerAlerts = (
  accountId: string | number,
  isActiveInstrument = "true"
) => {
  const url = [baseUrl, "account", accountId, "banner", "alerts"].join("/");
  const params = { isActiveInstrument };
  const requestKey = getKey(url, params);
  return new Promise<IAccountBannerAlerts>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getAccountBannerRuns = (
  accountId: string | number,
  isActiveInstrument = "true"
) => {
  const url = [baseUrl, "account", accountId, "banner", "runs"].join("/");
  const params = { isActiveInstrument };
  const requestKey = getKey(url, params);
  return new Promise<IAccountBannerRuns>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getAccountBannerUtilization = (
  accountId: string | number,
  isActiveInstrument = "true"
) => {
  const url = [baseUrl, "account", accountId, "banner", "utilization"].join("/");
  const params = { isActiveInstrument };
  const requestKey = getKey(url, params);
  return new Promise<IAccountBannerUtilization>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getMyAccountBanner = (accountId: string | number) => {
  const url = [baseUrl, "account", accountId, "banner"].join("/");
  const requestKey = getKey(url);
  return new Promise<IMyAccountBanner>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getMyAccountBannerInfo = (accountId: string | number) => {
  const url = [baseUrl, "account", accountId, "banner", "info"].join("/");
  const requestKey = getKey(url);
  return new Promise<IMyAccountBannerInfo>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getMyAccountBannerUtilization = (accountId: string | number) => {
  const url = [baseUrl, "account", accountId, "banner", "utilization"].join("/");
  const requestKey = getKey(url);
  return new Promise<IMyAccountBannerUtilization>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getMyAccountBannerAlerts = (accountId: string | number) => {
  const url = [baseUrl, "account", accountId, "banner", "alerts"].join("/");
  const requestKey = getKey(url);
  return new Promise<IMyAccountBannerAlerts>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getMyAccountBannerRuns = (accountId: string | number) => {
  const url = [baseUrl, "account", accountId, "banner", "runs"].join("/");
  const requestKey = getKey(url);
  return new Promise<IMyAccountBannerRuns>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getMyAccountBannerConnectivity = (accountId: string | number) => {
  const url = [baseUrl, "account", accountId, "banner", "connectivity"].join("/");
  const requestKey = getKey(url);
  return new Promise<IMyAccountBannerConnectivity>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getSearchResult = (searchText: string, isInternal: boolean, role: string) => {
  const url = [baseUrl, "search"].join("/");
  const params = {
    searchText,
    isInternal: isInternal ? "true" : "false",
    role,
  };
  const requestKey = getKey(url, params);
  return new Promise<ISearchResult>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export interface IInstrumentForTable {
  instruments: {
    serialNumber: string;
    nickname: string | null;
    type: string;
  };
  instrumentActive: boolean;
  instrumentInsight: IInsightData;
  connectivity: {
    serialNumber: string;
    bsshConnected: string;
    proactiveConnected: string;
    instrumentConnectivityStatus: string;
    instrumentConnectivityInsightStatus: string;
    isConnectivityInsightEnabled: boolean;
    message: string | null;
  };
  status: {
    serialNumber: string;
    status: string;
    currentCycle: string[];
  };
  runSpec?: {
    numOfPassedRunsLast30DaysPercent: number;
    delta: number;
  }
  activeAlerts: {
    serialNumber: string;
    activeAlerts: {
      Warning: any[];
      Info: any[];
      Critical: any[];
    };
  };
}

export interface IGetInstrumentTable {
  accountTables: IInstrumentForTable[];
}

export const getInstrumentTable = (
  accountID: string = "NULL",
  isActiveInstrument = "true"
) => {
  const url = [baseUrl, "account", accountID, "table"].join("/");
  const params = { isActiveInstrument };
  const requestKey = getKey(url, params);

  return new Promise<IInstrumentForTable[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export interface IAccountRecentRun {
  serialNumber: string;
  type: string;
  ipsId: string;
  runId: string;
  runStatus: {
    status: string;
    currentCycle: null | string;
  };
  runHealth: IInsightData;
  runSpec: IInsight;
  cycles: string;
  instrumentActive: boolean;
}

export const getAccountRuns = (
  accountID: string = "NULL",
  isActiveInstrument = "true"
) => {
  const url = [baseUrl, "account", accountID, "recent-runs"].join("/");
  const params = { isActiveInstrument };
  const requestKey = getKey(url, params);

  return new Promise<IAccountRecentRun[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getUserCases = (email: string) => {
  const url = [baseUrl, "case", "user", `${email}`].join("/");
  const requestKey = getKey(url);

  return new Promise<IUserCase[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getRunDownloadSize = (details: IRunFileSizeDetails[]) => {
  const url = [baseUrl, "run", "file-size"].join("/");
  const requestKey = getKey(url, {data: details});

  return new Promise<IRunSize[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .post(url, details )
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getMyInstrumentStats = (params: { serialNumbers: string }) => {
  const url: string = [baseUrl, "instrument", "banner"].join("/");
  const requestKey = getKey(url, params);
  return new Promise<IMyInstruments[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url, { params })
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getDownloadLink = (details: IRunDownloadDetails[]) => {
  const url: string = [baseUrl, "run", "download"].join("/");
  const requestKey = getKey(url, {data: details});

  return new Promise<string>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .post(url, details)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getMyAccount = (id: number) => {
  const url: string = [baseUrl, "user", id, "account"].join("/");
  const requestKey = getKey(url);
  return new Promise<{
    primaryAccounts: IMyAccountData[];
    secondaryAccounts: IMyAccountData[];
    customLists: { name: string; id: number; accounts: IMyAccountData[] }[];
  }>((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const getMyInstrument = (id: number) => {
  const url: string = [baseUrl, "user", id, "instrument"].join("/");
  const requestKey = getKey(url);
  return new Promise<{
    followInstruments: {
      name: string;
      id: number;
      instruments: IFollowedInstrument[];
    }[];
  }>((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const getMyAccountsUtilization = (id: number) => {
  const url: string = [baseUrl, "user", id, "utilization"].join("/");
  const requestKey = getKey(url);
  return new Promise<
    { utilizationPercentage: number; delta: number; accountId: string }[]
  >((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getInstrumentBreadcrumb = (id: string) => {
  const url: string = [baseUrl, "instrument", id, "breadcrumb"].join("/");
  const requestKey = getKey(url);
  return new Promise<InstrumentBreadcrumb[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getRunBreadcrumb = (id: string) => {
  const url: string = [baseUrl, "run", id, "breadcrumb"].join("/");
  const requestKey = getKey(url);
  return new Promise<RunBreadcrumb[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios
        .get(url)
        .then((res) => {
          dataStore[requestKey] = res.data;
          resolve(res.data);
        })
        .catch((err) => reject(err));
    }
  });
};

export const getInstrumentStatus = (id: string) => {
  const url: string = [baseUrl, "instrument"].join("/");
  const params = { serialNumbers: id };
  return new Promise<{ items: InstrumentStatus[] }>((resolve, reject) => {
    axios
      .get(url, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const getHardwareMetricList = (id: string) => {
  const url: string = [
    baseUrl,
    "instrument",
    "type",
    id,
    "metric-definition",
  ].join("/");
  const requestKey = getKey(url);

  return new Promise<HardwareMetricsList[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
    } else {
      axios.get(url)
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
    }
  });
};

export const getHardwareMetricData = (
  instrumentType: string,
  fromDate: string,
  toDate: string,
  serialNumber: string,
  body: HardwareMetricsList[],
) => {
  const url = [baseUrl, "instrument", "metric"].join("/");
  const params = {
    instrumentType,
    fromDate,
    toDate,
    serialNumber,
  };
  const requestKey = getKey(url, {params, body});

  return new Promise<HarwareMetricChartData[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .post(url, body, { params })
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getErrorAndWarning = (
  instrumentId: string,
  isInternal: boolean,
  role: string
) => {
  const url = [
    baseUrl,
    "instrument",
    instrumentId,
    "error-warning",
  ].join("/");
  const params = {
    isInternal: isInternal ? "true" : "false",
    role,
  };
  const requestKey = getKey(url, params);

  return new Promise<ErrorWarningData[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url, { params })
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getRunErrorAndWarning = (runId: string) => {
  const url = [baseUrl, "run", runId, "error-warning"].join("/");
  const requestKey = getKey(url);

  return new Promise<ErrorWarningData[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url)
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const checkPatternedFlowcell = (runId: string, env: string) => {
  const url = [baseUrl, "run", runId, "environment", env, "patterned-flowcell"].join("/");
  const requestKey = getKey(url);

  return new Promise<IPatternedFlowcell>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url)
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getInteropTile = (runId: string, env: string) => {
  const url = [baseUrl, "run", runId, "environment", env, "interop-tile"].join("/");
  const requestKey = getKey(url);

  return new Promise<IChartData[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url)
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getInstrumentTypes = () => {
  const url = [baseUrl, "instrument", "type"].join("/");
  const params = { isActive: true };
  const requestKey = getKey(url, params);

  return new Promise<string[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url, { params })
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getFiltersAvailable = (typs: string[]) => {
  const url = [baseUrl, "instrument", "type", "query-params"].join("/");
  const params = {
    instrumentTypes: typs.join(","),
  };
  const requestKey = getKey(url, params);

  return new Promise<
    { nam: string; dataType: string; systemGroup: string | null }[]
  >((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url, { params })
      .then((res) => {
        const data = res.data.map((ins) => ({
          nam: ins.name,
          dataType: ins.dataType,
          systemGroup: ins.systemGroup,
        }));
        dataStore[requestKey] = data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const getLogSearchFile = (body: IQueryParam) => {
  const url = [baseUrl, "search", "file"].join("/");
  const params = { searchText: body.searchText, runId: body.runId, environment: body.environment, proactiveUserId: body.proactiveUserId };
  const requestKey = getKey(url, params);

  return new Promise<IQueryParamTable[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
    .get(url, { params })
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getLogSearchStatus = (runId: number, env: string) => {
  const url = [baseUrl, "search", "file", "status"].join("/");
  const params = { runId: runId, environment: env };

  return new Promise<IRunStatus>((resolve, reject) => {
    axios
    .get(url, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const putLogSearchStatus = (details: {runId: number, environment: string}) => {
  const url = [baseUrl, "search", "file", "status"].join("/");
  const params = { runId: details.runId, environment: details.environment };

  return new Promise<IRunStatus>((resolve, reject) => {
    axios
    .put(url, null, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getRunFileDownload = (params: IFileDownloadParam) => {
  const url = [baseUrl, "run", "file", "download"].join("/");
  const requestKey = getKey(url, params);

  return new Promise<IFileDownload>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
    .get(url, { params })
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getLogSearchSummary = (params: IQuerySummaryParam) => {
  const url = [baseUrl, "search", "file", "summary"].join("/");

  return new Promise<IFileSummary[]>((resolve, reject) => {
    axios
    .get(url, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getLogSearchFullDetail = (params: IQueryParam) => {
  const url = [baseUrl, "search", "file", "full-detail"].join("/");
  const requestKey = getKey(url, params);

  return new Promise<IQueryParamTable[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
    .get(url, { params })
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getCustomQueryPreviewTable = (body: IQueryParamOptions) => {
  const url = [baseUrl, "instrument", "query"].join("/");
  const requestKey = getKey(url, body);

  return new Promise<IQueryParamPreviewTable>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .post(url, body)
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getCustomQueryPreviewTableLocal = (body: IQueryParamOptions) => {
  const url = [baseUrl, "instrument", "query"].join("/");
  const requestKey = getKey(url, body);

  return new Promise<IQueryParamPreviewTable>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url)
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getRunParamsAndConsumables = (id: string) => {
  const url = [baseUrl, "run", id, "run-parameters"].join("/");
  const params = { isConsumablesOnly: false };
  const requestKey = getKey(url, params);

  return new Promise<IRunParamAndConsumable[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url, { params })
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const saveQuery = (body: ISaveQueryParam) => {
  const url = [baseUrl, "instrument", "query", "name"].join("/");

  return new Promise<ISaveQueryParam>((resolve, reject) => {
    axios
      .post(url, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getQueries = (id: number | "") => {
  const url = [baseUrl, "instrument", "query", "name"].join("/");
  const params = { proactiveUserId: id };
  return new Promise<ISaveQueryParam[]>((resolve, reject) => {
    axios
      .get(url, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const deleteQuery = (nam: string, id: number | "") => {
  const url = [baseUrl, "instrument", "query", "name"].join("/");
  const params = { queryName: nam, proactiveUserId: id };

  return new Promise<boolean>((resolve, reject) => {
    axios
      .delete(url, { params })
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        reject(false);
      });
  });
};

export const getDTAUrl = () => {
  const url = [baseUrl, "dta", "url"].join("/");
  return new Promise<string>((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

export const getAllAlertCMS = () => {
  const url = [baseUrl, "alert", "info"].join("/");
  return new Promise<IAlertData[]>((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getAlertCMS = (name: string) => {
  const url = [baseUrl, "alert", "info"].join("/");
  return new Promise<IAlertData[]>((resolve, reject) => {
    axios
      .get(url, { params: { alertName: name } })
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const updateCMSAlert = (dt: ISendAlertData) => {
  const url = [baseUrl, "alert", "info"].join("/");
  return new Promise<Boolean>((resolve, reject) => {
    axios
      .put(url, dt)
      .then(() => resolve(true))
      .catch(reject);
  });
};

export const newCMSAlert = (dt: ISendAlertData) => {
  const url = [baseUrl, "alert", "info"].join("/");
  return new Promise<Boolean>((resolve, reject) => {
    axios
      .post(url, dt)
      .then(() => resolve(true))
      .catch(reject);
  });
};

export const removeCMSAlert = (id: string) => {
  const url = [baseUrl, "alert", "info"].join("/");
  return new Promise<Boolean>((resolve, reject) => {
    axios
      .delete(url, { data: { alertName: id } })
      .then(() => resolve(true))
      .catch(reject);
  });
};

export interface IGetHistoricalAndService
  extends IGetServiceEvents,
    IGetHistoricalRuns {}

export const getHistoricalAndService = (params: IGetHistoricalAndService) => {
  const historical = getHistoricalRuns(params);
  const service = getServiceEvents(params);

  return Promise.all([historical, service]);
};

export const getMyInsights = (id: string | number) => {
  const url = [baseUrl, "user", id, "instrument", "connectivity", "insight"].join("/");
  const requestKey = getKey(url);

  return new Promise<IUserInsights[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url)
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
}

export const getMyCases = (id: string | number) => {
  const url = [baseUrl, "user", id, "case"].join("/");
  const requestKey = getKey(url);

  return new Promise<IUserCaseCall>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url)
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getMyRecentRuns = (
  fromDate: string,
  toDate: string,
  id: string | number
) => {
  const url = [baseUrl, "user", id, "run"].join("/");
  const params = { fromDate, toDate };
  const requestKey = getKey(url, params);

  return new Promise<IUserRecentRun[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url, { params })
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getMyRecentAlerts = (
  fromDate: string,
  toDate: string,
  id: string | number
) => {
  const url = [baseUrl, "user", id, "alert"].join("/");
  const params = { fromDate, toDate };
  const requestKey = getKey(url, params);

  return new Promise<IUserRecentAlert[]>((resolve, reject) => {
    if (requestKey in dataStore) {
      resolve(dataStore[requestKey]);
      return;
    }
    axios
      .get(url, { params })
      .then((res) => {
        dataStore[requestKey] = res.data;
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getCustomAccountList = (id: number | string) => {
  const url = [baseUrl, "user", id, "account", "custom-list"].join("/");

  return new Promise<IUserAccountList[]>((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const addNewAccountList = (id: string | number, name: string) => {
  const url: string = [baseUrl, "user", id, "account"].join("/");

  return new Promise<IUserAccountList>((resolve, reject) => {
    axios
      .post(url, { name })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const addAccountToList = (
  id,
  details: { saveUserAccountId: number; sapAccountId?: string; name?: string }
) => {
  const url: string = [baseUrl, "user", id, "account"].join("/");

  return new Promise<IUserAccountList>((resolve, reject) => {
    axios
      .put(url, details)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const removeAccountFromList = (
  id,
  details: { saveUserAccountId: number; sapAccountId?: string }
) => {
  const url: string = [baseUrl, "user", id, "account"].join("/");

  return new Promise<IUserAccountList>((resolve, reject) => {
    axios
      .delete(url, { data: details })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const getCustomInstrumentList = (id: number | string) => {
  const url = [baseUrl, "user", id, "instrument", "custom-list"].join("/");

  return new Promise<IUserInstrumentList[]>((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const addNewInstrumentList = (id: string | number, name: string) => {
  const url: string = [baseUrl, "user", id, "instrument"].join("/");

  return new Promise<IUserInstrumentList>((resolve, reject) => {
    axios
      .post(url, { name })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const addInstrumentToList = (
  id,
  details: {
    saveUserInstrumentId: number;
    serialNumber?: string;
    name?: string;
  }
) => {
  const url: string = [baseUrl, "user", id, "instrument"].join("/");

  return new Promise<IUserInstrumentList>((resolve, reject) => {
    axios
      .put(url, details)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const removeInstrumentFromList = (
  id,
  details: { saveUserInstrumentId: number; serialNumber?: string }
) => {
  const url: string = [baseUrl, "user", id, "instrument"].join("/");

  return new Promise<IUserInstrumentList>((resolve, reject) => {
    axios
      .delete(url, { data: details })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};